"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _encrypt = _interopRequireDefault(require("./encrypt"));

// 数据加密
var key = process.env.VUE_APP_KEY;
/**
 * @param {} config 请求设置
 */

var encryptData = function encryptData(config) {
  console.log('dataHandle :', _encrypt.default); // const needEncryptData = JSON.stringify(config.data)

  var needEncryptData = typeof config.data === 'string' ? config.data : JSON.stringify(config.data || {});

  var encryptData = _encrypt.default.encrypt(needEncryptData, key);

  var nonce = _encrypt.default.createNonce();

  var timestamp = _encrypt.default.createTimestamp();

  var sign = _encrypt.default.createSign(encryptData, nonce, timestamp, key);

  console.log(encryptData);
  config.data = encryptData;
  config.headers['Content-Type'] = 'application/json'; // config.headers['zr-nonce'] = nonce
  // config.headers['zr-timestamp'] = timestamp
  // config.headers['zr-gzipped'] = true
  // config.headers['zr-encrypted'] = true
  // config.headers['zr-sign'] = sign
  // 新加密

  var obj = {
    encrypted: true,
    gzipped: true,
    timestamp: timestamp,
    nonce: nonce,
    sign: sign,
    keyId: process.env.VUE_APP_KEY_ID,
    platform: 'h5',
    application: 'game_manager',
    // version: '1.0',
    version: process.env.VUE_APP_KEY_VERSION,
    signtype: 'SHA256'
  };
  config.headers['token'] = _encrypt.default.encrypt(obj, key);
  return config;
};

var decryptData = function decryptData(data) {
  return _encrypt.default.decrypt(data, key);
};

var _default = {
  encryptData: encryptData,
  decryptData: decryptData
};
exports.default = _default;