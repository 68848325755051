"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));

var _i18n = require("@/utils/i18n");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    generateTitle: _i18n.generateTitle,
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;

      var toPath = _pathToRegexp.default.compile(path);

      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    }
  }
};
exports.default = _default;