"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.betPointManageSearh = betPointManageSearh;
exports.betPointManageStart = betPointManageStart;
exports.betPointManageUpdate = betPointManageUpdate;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 游戏玩法管理-查询玩法
 * @param {
 * "id": 0,
 * "page": 0,
 * "pageSize": 0
 * }
 */
function betPointManageSearh(params) {
  return (0, _request.default)({
    url: '/gameManage/betPointManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 游戏玩法管理-启用/停用
 * @param {
 * id: number
 * }
 */


function betPointManageStart(data) {
  return (0, _request.default)({
    url: '/gameManage/betPointManage/modifyBetPointStatus',
    method: 'post',
    data: data
  });
}
/**
 * 游戏玩法管理-修改游戏玩法牌靴局数限制
 * @param {
 * bootNumberLimit: number,
 * id: number,
 * name: string,
 * playRate: string,
 * status:number
 * }
 */


function betPointManageUpdate(data) {
  return (0, _request.default)({
    url: '/gameManage/betPointManage/update',
    method: 'post',
    data: data
  });
}

var _default = {
  betPointManageSearh: betPointManageSearh,
  betPointManageUpdate: betPointManageUpdate,
  betPointManageStart: betPointManageStart
};
exports.default = _default;