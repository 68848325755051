"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.abnormaltManageCollectionSave = abnormaltManageCollectionSave;
exports.abnormaltManageWaitDo = abnormaltManageWaitDo;
exports.abnormaltManageSearch = abnormaltManageSearch;
exports.page_betting_record = page_betting_record;
exports.list_betting_record = list_betting_record;
exports.abnormaltManageSureInverseCard = abnormaltManageSureInverseCard;
exports.abnormalManageReCalRound = abnormalManageReCalRound;
exports.abnormalManageGetCardType = abnormalManageGetCardType;
exports.update_card = update_card;
exports.preview_influence = preview_influence;
exports.abnormalManageReCalRoundSubmit = abnormalManageReCalRoundSubmit;
exports.cancelRound = cancelRound;
exports.apply_search = apply_search;
exports.preview = preview;
exports.applyRound = applyRound;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 异常局管理-修改-获取已处理数据
 * @param {
 *  "gameTypeId": 0,
 * 	"id": 0,
 *  "result": "string",
 *	"roundNextDTOS": [
 *  	{
 *	 		"cardColor": 0,
 *	 		"cardIndex": 0,
 *	 		"cardNumber": 0,
 *	 		"cardOwner": 0,
 *	 		"id": 0,
 *	 		"ownerIndex": 0,
 *	 		"roundId": 0,
 *	 		"roundNo": "string"
 *   	}
 * 	]
 * }
 */
function abnormaltManageCollectionSave(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormaltManage/collectionSave',
    method: 'post',
    data: data
  });
}
/**
 * 异常局管理 - 修改 - 获取待处理数据
 * @param {
 * "id": number,
 * }
 */


function abnormaltManageWaitDo(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormaltManage/waitDo',
    method: 'get',
    params: params
  });
} // /**
//  * 异常局管理 - 查询
//  * @param {
//  * "gameTypeId": 0,
//  * "roundNo": "string",
//  * "status": "number", 0为待处理，1为已处理,
//  * "pageIndex": 1,
//  * "pageSize": 10
//  * }
//  */
// export function abnormaltManageSearch(params) {
// 	return request({
// 		url: '/gameManage/abnormalManage/search',
// 		method: 'get',
// 		params
// 	})
// }

/**
 * 待申请列表-分页查询
 * @param {
 * "gameTypeId": 0,
 * "roundNo": "string",
 * "pageIndex": 1,
 * "pageSize": 10
 * }
 */


function abnormaltManageSearch(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/wait_apply_search',
    method: 'get',
    params: params
  });
} // 影响玩家-分页查询


function page_betting_record(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/page_betting_record',
    method: 'get',
    params: params
  });
} // 影响玩家-明细查询


function list_betting_record(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/list_betting_record',
    method: 'get',
    params: params
  });
}
/**
 * 异常局管理 - 提交数据获取新结果
 * @param {
 *	[
 * 		{
 *   		"cardNumber": 0,
 *   		"cardOwner": 0,
 *   		"gameTypeId": 0,
 *   		"id": 0
 * 		}
 *	]
 * }
 */


function abnormaltManageSureInverseCard(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormaltManage/searchCardResult',
    method: 'post',
    data: data
  });
} // 重算游戏局


function abnormalManageReCalRound(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/reCalRound',
    method: 'get',
    params: params
  });
} // 获取重算牌型


function abnormalManageGetCardType(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/getCardType',
    method: 'post',
    data: data
  });
} // 重算局-修改牌结果


function update_card(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/update_card',
    method: 'post',
    data: data
  });
} // 查询预览结果


function preview_influence(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/preview-influence',
    method: 'get',
    params: params
  });
} // 重算局提交


function abnormalManageReCalRoundSubmit(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/reCalRoundSubmit',
    method: 'post',
    data: data
  });
} // 异常局撤销


function cancelRound(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/cancelRound',
    method: 'post',
    data: data
  });
} // 申请记录


function apply_search(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/apply_search',
    method: 'get',
    params: params
  });
} // 异常局预览


function preview(params) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/preview',
    method: 'get',
    params: params
  });
} // 异常局-申请


function applyRound(data) {
  return (0, _request.default)({
    url: '/gameManage/abnormalManage/v1/applyRound',
    method: 'post',
    data: data
  });
}

var _default = {
  abnormaltManageSureInverseCard: abnormaltManageSureInverseCard,
  abnormaltManageCollectionSave: abnormaltManageCollectionSave,
  abnormaltManageWaitDo: abnormaltManageWaitDo,
  abnormaltManageSearch: abnormaltManageSearch,
  abnormalManageReCalRound: abnormalManageReCalRound,
  abnormalManageReCalRoundSubmit: abnormalManageReCalRoundSubmit,
  abnormalManageGetCardType: abnormalManageGetCardType,
  cancelRound: cancelRound,
  apply_search: apply_search,
  page_betting_record: page_betting_record,
  list_betting_record: list_betting_record,
  applyRound: applyRound,
  preview: preview,
  update_card: update_card,
  preview_influence: preview_influence
};
exports.default = _default;