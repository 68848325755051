"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _user = require("@/api/user");

var _account = require("@/api/account");

var _auth = require("@/utils/auth");

var _router = _interopRequireWildcard(require("@/router"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var state = {
  token: (0, _auth.getToken)(),
  name: (0, _auth.getUserName)(),
  avatar: '',
  userInfo: (0, _auth.getUserInfo)() || {}
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password,
        googleAuth = userInfo.googleAuth;
    return new Promise(function (resolve, reject) {
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
      localStorage.setItem('googleAuth', googleAuth);
      (0, _user.login)({
        username: username.trim(),
        password: password.trim(),
        googleAuth: googleAuth,
        type: '1'
      }).then(function (response) {
        console.log('response :', response);
        var data = response.data;
        commit('SET_TOKEN', data.token);
        (0, _auth.setToken)(data.token);
        commit('SET_USERINFO', _objectSpread(_objectSpread({}, data.userInfo), {}, {
          platformId: data.platformId
        }));
        (0, _auth.setUserInfo)(_objectSpread(_objectSpread({}, data.userInfo), {}, {
          platformId: data.platformId
        }));
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _account.accountManageUserPermission)().then(function (res) {
        // res.data.listPermission.push({
        // 	component: '/gamemanagement/pclabelNew',
        // 	icon: 'bb_pc',
        // 	id: '101050000',
        // 	orderNum: '122',
        // 	parentId: '101000000',
        // 	path: '/101000000/101050000',
        // 	permissionName: 'gamepclabel',
        // 	type: '0'
        // })
        // res.data.listPermission.push({
        // 	component: '/gamemanagement/mobilelabelNew',
        // 	icon: 'bb_mobile',
        // 	id: '101060000',
        // 	orderNum: '123',
        // 	parentId: '101000000',
        // 	path: '/101000000/101060000',
        // 	permissionName: 'gamemobilelabel',
        // 	type: '0'
        // })
        // 推荐管理
        // res.data.listPermission.push({
        // 	component: '/gamemanagement/recommendManagement',
        // 	icon: 'bb_mobile',
        // 	id: '101070000',
        // 	orderNum: '124',
        // 	parentId: '101000000',
        // 	path: '/101000000/101070000',
        // 	permissionName: '推荐管理',
        // 	type: '0'
        // })
        var userInfo = (0, _auth.getUserInfo)() || {};
        userInfo.roles = res.data.listPermission;

        if (userInfo) {
          var username = userInfo.username,
              avatar = userInfo.avatar;
          commit('SET_NAME', username);
          commit('SET_AVATAR', avatar);
          (0, _auth.setUserInfo)(userInfo);
          resolve(userInfo);
        } else {
          reject({
            userInfo: userInfo,
            message: '获取用户数据失败'
          });
        }
      }).catch(function (err) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();

        _router.default.push("/login");

        reject(err);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        (0, _auth.removeUserInfo)();
        (0, _router.resetRouter)();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;