"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ipBlackListManageSearch = ipBlackListManageSearch;
exports.ipBlackListManageSave = ipBlackListManageSave;
exports.ipBlackListManageTemplateDown = ipBlackListManageTemplateDown;
exports.ipBlackListManageSaveBatch = ipBlackListManageSaveBatch;
exports.ipBlackListManageDelete = ipBlackListManageDelete;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * ip黑名单 - 分页列表
 * @param {
 *  id: number
 * }
 */
function ipBlackListManageSearch(params) {
  return (0, _request.default)({
    url: '/systemManage/ipBlackListManage/search',
    method: 'get',
    params: params
  });
}
/**
 * ip黑名单 - 添加
 * @param {
 *   "endIp": "string",
 *   "ipScopeId": 0,
 *   "remark": "string",
 *   "startIp": "string"
 * }
 */


function ipBlackListManageSave(data) {
  return (0, _request.default)({
    url: '/systemManage/ipBlackListManage/save',
    method: 'post',
    data: data
  });
}
/**
 * ip黑名单 - 模板下载
 */


function ipBlackListManageTemplateDown(params) {
  return (0, _request.default)({
    url: '/systemManage/ipBlackListManage/templateDown',
    method: 'get',
    params: params
  });
}
/**
 * ip黑名单 - 上传CSV文件
 * @param {
 *   Filedata
 * }
 */


function ipBlackListManageSaveBatch(data) {
  return (0, _request.default)({
    url: '/systemManage/ipBlackListManage/saveBatch',
    method: 'post',
    data: data
  });
}
/**
 * ip黑名单 - 上传CSV文件
 * @param {
 *   ids: []
 * }
 */


function ipBlackListManageDelete(data) {
  return (0, _request.default)({
    url: '/systemManage/ipBlackListManage/delete',
    method: 'post',
    data: data
  });
}

var _default = {
  ipBlackListManageSearch: ipBlackListManageSearch,
  ipBlackListManageSave: ipBlackListManageSave,
  ipBlackListManageTemplateDown: ipBlackListManageTemplateDown,
  ipBlackListManageSaveBatch: ipBlackListManageSaveBatch,
  ipBlackListManageDelete: ipBlackListManageDelete
};
exports.default = _default;