"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountManageCreate = accountManageCreate;
exports.accountManageModifyUsers = accountManageModifyUsers;
exports.accountManageSearch = accountManageSearch;
exports.accountManageUpdateUser = accountManageUpdateUser;
exports.accountManageUserDetail = accountManageUserDetail;
exports.accountManageUserPermission = accountManageUserPermission;
exports.accountManageUpdatePass = accountManageUpdatePass;
exports.getGoogleAuth = getGoogleAuth;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 账号管理-创建
 * @param {
 * "pwd": "string",
 * "rePwd": "string",
 * "roleIds": [
 *   "string"
 * ],
 * "userName": "string"
 * }
 */
function accountManageCreate(data) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/create',
    method: 'post',
    data: data
  });
}
/**
 * 账号管理-修改用户状态
 * @param {
 * "id": "string",
 * "status": "string",
 * }
 */


function accountManageModifyUsers(data) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/modifyUsersStatus',
    method: 'post',
    data: data
  });
}
/**
 * 账号管理-查询
 * @param {
 * startAt: string
 * endAt: string
 * status: number
 * userName: string
 * createBy: string
 * page: number
 * pageSize: number
 * }
 */


function accountManageSearch(params) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/search',
    method: 'get',
    params: params
  });
}
/**
 * 账号管理-更新用户信息
 * @param {
 * id: number
 * remark: string
 * roleIds: [
 *  "string"
 * ]
 * }
 */


function accountManageUpdateUser(data) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/updateUser',
    method: 'post',
    data: data
  });
}
/**
 * 账号管理-查询用户详细信息
 * @param {
 * id: number
 */


function accountManageUserDetail(params) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/userDetail',
    method: 'get',
    params: params
  });
}
/**
 * 账号管理-查询用户权限信息
 */


function accountManageUserPermission(params) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/userPermission',
    method: 'get',
    params: params
  });
}
/**
 * 账号管理-修改密码
 * @param {
 *	"id": 0,
 *  "password": "string",
 *  "surePassword": "string"
 * }
 */


function accountManageUpdatePass(data) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/updatePass',
    method: 'post',
    data: data
  });
} // 生成谷歌秘钥


function getGoogleAuth(params) {
  return (0, _request.default)({
    url: '/systemManage/accountManage/googleAuth',
    method: 'get',
    params: params
  });
}

var _default = {
  accountManageCreate: accountManageCreate,
  accountManageModifyUsers: accountManageModifyUsers,
  accountManageSearch: accountManageSearch,
  accountManageUpdateUser: accountManageUpdateUser,
  accountManageUserDetail: accountManageUserDetail,
  accountManageUserPermission: accountManageUserPermission,
  accountManageUpdatePass: accountManageUpdatePass,
  getGoogleAuth: getGoogleAuth
};
exports.default = _default;