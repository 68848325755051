"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryLookUp = queryLookUp;
exports.downloadTaskList = downloadTaskList;
exports.downloadTaskRepeat = downloadTaskRepeat;
exports.downloadTaskDel = downloadTaskDel;
exports.downloadTaskBatchDel = downloadTaskBatchDel;
exports.batchDownloadExcle = batchDownloadExcle;
exports.listOpeLogType = listOpeLogType;
exports.operationLogList = operationLogList;
exports.ipWhiteListSave = ipWhiteListSave;
exports.ipWhiteListSearch = ipWhiteListSearch;
exports.ipWhiteListUpdate = ipWhiteListUpdate;
exports.ipWhiteListUpdateStatus = ipWhiteListUpdateStatus;
exports.platformMaintaince = platformMaintaince;
exports.platformStatus = platformStatus;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 下拉框配置
 */
function queryLookUp(params) {
  return (0, _request.default)({
    url: '/lookupManage/queryLookUp',
    method: 'get',
    params: params
  });
} // 获取列表


function downloadTaskList(params) {
  return (0, _request.default)({
    url: '/async/excel/search',
    method: 'get',
    params: params
  });
} // 重复执行


function downloadTaskRepeat(params) {
  return (0, _request.default)({
    url: '/async/excel/repeat',
    method: 'get',
    params: params
  });
}

function downloadTaskDel(data) {
  return (0, _request.default)({
    url: '/async/excel/batch/delete',
    method: 'post',
    data: data
  });
} // 批量删除


function downloadTaskBatchDel(data) {
  return (0, _request.default)({
    url: '/async/excel/batch/delete',
    method: 'post',
    data: data
  });
} // 批量导出 不处理


function batchDownloadExcle(params) {
  return (0, _request.default)({
    url: '/excel/batch/download',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}
/**
 * 获取操作日志类型
 */


function listOpeLogType(params) {
  return (0, _request.default)({
    url: '/lookupManage/listOpeLogType',
    method: 'get',
    params: params
  });
}
/**
 * 操作日志列表
 */


function operationLogList(params) {
  return (0, _request.default)({
    url: '/systemManage/operationLogManage/operationLogList',
    method: 'get',
    params: params
  });
} // IP白名单单个添加


function ipWhiteListSave(data) {
  return (0, _request.default)({
    url: '/systemManage/ipWhiteListManage/save',
    method: 'post',
    data: data
  });
} // IP白名单搜索列表


function ipWhiteListSearch(params) {
  return (0, _request.default)({
    url: '/systemManage/ipWhiteListManage/search',
    method: 'get',
    params: params
  });
} // IP白名单单个修改


function ipWhiteListUpdate(data) {
  return (0, _request.default)({
    url: '/systemManage/ipWhiteListManage/update',
    method: 'post',
    data: data
  });
} // IP白名单状态修改


function ipWhiteListUpdateStatus(data) {
  return (0, _request.default)({
    url: '/systemManage/ipWhiteListManage/updateStatus',
    method: 'post',
    data: data
  });
} // 平台维护


function platformMaintaince(params) {
  return (0, _request.default)({
    url: '/gameManage/platformManage/platformMaintaince',
    method: 'get',
    params: params
  });
} // 平台维护状态查询


function platformStatus(params) {
  return (0, _request.default)({
    url: '/gameManage/platformManage/platformStatus',
    method: 'get',
    params: params
  });
}

var _default = {
  downloadTaskList: downloadTaskList,
  downloadTaskDel: downloadTaskDel,
  downloadTaskRepeat: downloadTaskRepeat,
  downloadTaskBatchDel: downloadTaskBatchDel,
  batchDownloadExcle: batchDownloadExcle,
  queryLookUp: queryLookUp,
  listOpeLogType: listOpeLogType,
  operationLogList: operationLogList,
  ipWhiteListSave: ipWhiteListSave,
  ipWhiteListSearch: ipWhiteListSearch,
  ipWhiteListUpdate: ipWhiteListUpdate,
  ipWhiteListUpdateStatus: ipWhiteListUpdateStatus,
  platformMaintaince: platformMaintaince,
  platformStatus: platformStatus
};
exports.default = _default;