"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _index = require("@/utils/lang/index");

var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  language: (0, _index.getLanguage)(),
  lookUpData: '',
  // 下载中心
  downloadVisible: false
};
var mutations = {
  SET_DOWNLOAD: function SET_DOWNLOAD(state, value) {
    state.downloadVisible = value;
  },
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);

    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  GET_LOOKUPDATA: function GET_LOOKUPDATA(state, data) {
    state.lookUpData = data;
  },
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;

    _jsCookie.default.set('language', language);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  getLookUpdata: function getLookUpdata(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      (0, _system.queryLookUp)().then(function (res) {
        if (res.code === 200) {
          commit('GET_LOOKUPDATA', res.data);
        }

        resolve(res.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setLanguage: function setLanguage(_ref6, language) {
    var commit = _ref6.commit;
    commit('SET_LANGUAGE', language);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;