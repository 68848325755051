"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hallTabManageSearchTab = hallTabManageSearchTab;
exports.hallTabManageSubmit = hallTabManageSubmit;
exports.hallTabManageLeftTabInfo = hallTabManageLeftTabInfo;
exports.hallTabManageSearchTableList = hallTabManageSearchTableList;
exports.hallTabManageDelete = hallTabManageDelete;
exports.hallTabManageSort = hallTabManageSort;
exports.getLabelTable = getLabelTable;
exports.getLabelTopTable = getLabelTopTable;
exports.updateLabelTable = updateLabelTable;
exports.recommentManageSearch = recommentManageSearch;
exports.recommentManageOption = recommentManageOption;
exports.default = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * PC端标签/移动标签
 *  type: number,
 * }
 * @param params
 */
function hallTabManageSearchTab(params) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/searchTab',
    method: 'get',
    params: params
  });
}
/**
 * PC端标签/移动标签-分配桌子-提交
 *   "habTableReqDTOS": [
 *    {
 *      "gameTableId": 0,
 *      "sort": 0
 *    }
 *   ],
 *   "id": 0,
 *   "type": 0
 * }
 * @param data
 */


function hallTabManageSubmit(data) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/submit',
    method: 'post',
    data: data
  });
}
/**
 * PC端标签/移动标签大厅标签分配桌台 左边框
 *   "tabId": 0,
 *   "type": 0
 * }
 * @param params
 */


function hallTabManageLeftTabInfo(params) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/leftTabInfo',
    method: 'get',
    params: params
  });
}
/**
 * PC端标签/移动标签 获取具体tab数据
 *   "tabId": 0,
 *   "type": 0
 * }
 * @param params
 */


function hallTabManageSearchTableList(params) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/searchTableList',
    method: 'get',
    params: params
  });
}
/**
 * PC端标签/移动标签 获取具体tab数据 前端传入:hallTabTableId字段值
 * id: 0
 * }
 * @param data
 */


function hallTabManageDelete(data) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/delete',
    method: 'post',
    data: data
  });
}
/**
 * PC端标签/移动标签 拖拽排序
 [
 {
        "gameTableId": 0,
        "sort": 0,
        "tabId": 0,
        "type": 0
        }
 ]
 * @param data
 */


function hallTabManageSort(data) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/sort',
    method: 'post',
    data: data
  });
}
/**
 *
 * @param data
 * @returns {Promise}
 */


function getLabelTable(params) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/getLabelTable',
    method: 'get',
    params: params
  });
}
/**
 *
 * @param data
 * @returns {Promise}
 */


function getLabelTopTable(params) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/getLabelTopTable',
    method: 'get',
    params: params
  });
}
/**
 *
 * @param data
 * @returns {Promise}
 */


function updateLabelTable(data) {
  return (0, _request.default)({
    url: '/gameManage/hallTabManage/updateLabelTable',
    method: 'post',
    data: data
  });
}
/**
 *
 * @param data
 * @returns {Promise}
 */


function recommentManageSearch() {
  return (0, _request.default)({
    url: '/recommentManage/search',
    method: 'get'
  });
}
/**
 *Option
 * @param data
 * @returns {Promise}
 */


function recommentManageOption(data) {
  return (0, _request.default)({
    url: '/recommentManage/option',
    method: 'post',
    data: data
  });
}

var _default = {
  hallTabManageSearchTab: hallTabManageSearchTab,
  hallTabManageSubmit: hallTabManageSubmit,
  hallTabManageLeftTabInfo: hallTabManageLeftTabInfo,
  hallTabManageSearchTableList: hallTabManageSearchTableList,
  hallTabManageDelete: hallTabManageDelete,
  hallTabManageSort: hallTabManageSort,
  getLabelTable: getLabelTable,
  getLabelTopTable: getLabelTopTable,
  updateLabelTable: updateLabelTable,
  recommentManageOption: recommentManageOption,
  recommentManageSearch: recommentManageSearch
};
exports.default = _default;