"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _toConsumableArray2 = _interopRequireDefault(require("/root/.jenkins/workspace/pre4-vue-admin-game.build/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Download',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isRefresh: false,
      listLoading: false,
      actionLoading: false,
      downloadLoading: false,
      multipleSelection: [],
      dataList: [],
      page: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        pageSizes: [5, 10, 20, 50, 100, 200, 500],
        layout: 'total, sizes, prev, pager, next, jumper'
      }
    };
  },
  computed: {
    disableBthDownld: function disableBthDownld() {
      if (this.multipleSelection && this.multipleSelection.length) {
        return !this.multipleSelection.every(function (o) {
          return +o.taskStatus == 1;
        });
      }

      return true;
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler: function handler(v) {
        this.dataList = [];
        this.multipleSelection = [];

        if (v) {
          this.page.total = 0;
          this.page.pageIndex = 1;
          this.page.pageSize = 10;
          this.getData();
        }
      }
    }
  },
  methods: {
    // 批量下载
    handleBatchDownload: function handleBatchDownload() {
      // this.downloadLoading = true
      // this.$api.batchDownloadExcle({ ids: this.multipleSelection.map(o => o.id).join(',') }).then(() => {
      //   this.downloadLoading = false
      // }).catch(() => {
      //   this.downloadLoading = false
      // })
      this.downloadAction((0, _toConsumableArray2.default)(this.multipleSelection));
    },
    // 批量删除
    handleBacthDel: function handleBacthDel() {
      var ids = this.multipleSelection.map(function (o) {
        return o.id;
      }).join(',');
      this.delAction({
        ids: ids
      });
    },
    // 下载
    handleDownload: function handleDownload(row) {
      this.downloadAction([row]); // this.$api.downloadExcle({ url: row.taskInterface, id: row.id })
    },
    // 重新导出
    handleReExport: function handleReExport(row) {
      var _this = this;

      this.$api.downloadTaskRepeat({
        id: row.id
      }).then(function (_) {
        if (_.code === 200) {
          _this.$message.success(_this.$t('message.successfulOperation'));

          _this.getData();
        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    // 删除
    handleDel: function handleDel(row) {
      var _this2 = this;

      this.$api.downloadTaskDel({
        ids: row.id
      }).then(function (_) {
        if (_.code === 200) {
          _this2.$message({
            message: _this2.$t('message.successfullyDeleted'),
            type: 'success'
          });

          _this2.getData();
        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log('multipleSelection:', this.multipleSelection);
    },
    isSelectable: function isSelectable(row) {
      return ![2, 3].includes(+row.taskStatus);
    },
    cancel: function cancel() {
      this.$store.commit('app/SET_DOWNLOAD', false);
    },
    handleSizeChange: function handleSizeChange(value) {
      this.page.pageSize = value;
      this.page.pageIndex = 1;
      this.getData();
    },
    handleCurrentChange: function handleCurrentChange(value) {
      this.page.pageIndex = value;
      this.getData();
    },
    downloadAction: function downloadAction(list) {
      // const that = this
      for (var i = 0; i < list.length; i++) {
        ;

        (function (j) {
          var tm = setTimeout(function () {
            var row = list[j];
            var names = row.downloadUrl.split('.');
            var fileName = row.taskName + '.' + names[names.length - 1]; // const fileName = that.transTaskName(row)

            console.log('下载名字：', fileName); // const link = document.createElement('a')
            // link.style.display = 'none'
            // link.href = row.downloadUrl
            // link.setAttribute('data-id', `id_${j}`)
            // link.setAttribute('download', fileName)
            // document.body.appendChild(link)
            // link.click()
            // document.body.removeChild(link)

            var x = new window.XMLHttpRequest();
            x.open('GET', row.downloadUrl, true);
            x.responseType = 'blob';

            x.onload = function () {
              var url = window.URL.createObjectURL(x.response);
              var a = document.createElement('a');
              a.href = url;
              a.target = '_blank';
              a.download = fileName;
              a.style.display = 'none';
              document.body.append(a);
              a.click();
              document.body.removeChild(a);
              clearTimeout(tm);
            };

            x.send();
          }, 10);
        })(i);
      }
    },
    delAction: function delAction(para) {
      var _this3 = this;

      this.actionLoading = true;
      this.$api.downloadTaskBatchDel(para).then(function (_) {
        _this3.actionLoading = false;

        if (_.code === 200) {
          _this3.$message({
            message: _this3.$t('message.successfullyDeleted'),
            type: 'success'
          });

          _this3.getData();
        }
      }).catch(function (e) {
        console.log(e);
        _this3.actionLoading = false;
      });
    },
    getData: function getData(refresh) {
      var _this4 = this;

      var para = {
        pageIndex: this.page.pageIndex,
        pageSize: this.page.pageSize
      };
      this.multipleSelection = [];
      this.listLoading = true;
      this.isRefresh = true;
      this.$api.downloadTaskList(para).then(function (_) {
        _this4.listLoading = false;

        if (_.code === 200) {
          var data = _.data;
          _this4.dataList = data.record || [];
          _this4.page.total = data.totalRecord;

          if (refresh) {
            var tm = setTimeout(function () {
              _this4.isRefresh = false;
              clearTimeout(tm);
            }, 3000);
          } else {
            _this4.isRefresh = false;
          }
        } else {
          _this4.isRefresh = false;
        }
      }).catch(function (e) {
        console.log(e);
        _this4.listLoading = false;
        _this4.isRefresh = false;
      });
    },
    transTaskName: function transTaskName(row) {
      var lang = _jsCookie.default.get('language');

      if (lang === 'zh') {
        lang = 'cn';
      }

      var nameKey = "taskName".concat(lang.slice(0, 1).toUpperCase()).concat(lang.slice(1));
      console.log('nameKey:', nameKey);
      return row[nameKey]; // return transExcleName(name)
    }
  }
};
exports.default = _default;